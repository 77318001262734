module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="content">\r\n        <div class="logo">\r\n            <img src="' +
((__t = ( require('./images/footer-logo.png') )) == null ? '' : __t) +
'" alt="">\r\n        </div>\r\n        <div class="dl">\r\n            <dl>\r\n                <dt>PRODUCTS</dt>\r\n                <dd><a href="javascript:;">Sample Centrifugation</a></dd>\r\n                <dd><a href="javascript:;">Specimen Collection</a></dd>\r\n                <dd><a href="javascript:;">Cryogenic</a></dd>\r\n                <dd><a href="javascript:;">Liquid Handling</a></dd>\r\n                <dd><a href="javascript:;">\r\n                    Saliva Sample Collection Kit</a></dd>\r\n            </dl>\r\n            <dl>\r\n                <dt>ABOUT</dt>\r\n                <dd><a href="javascript:;">Company Profile</a></dd>\r\n                <dd><a href="javascript:;">Our team</a></dd>\r\n                <dd><a href="javascript:;">Our advantages</a></dd>\r\n            </dl>\r\n            <dl>\r\n                <dt>CERTIFICATES</dt>\r\n                <dd><a href="javascript:;">Certificates</a></dd>\r\n            </dl>\r\n            <dl>\r\n                <dt>CONTACTUS</dt>\r\n                <dd>\r\n                    <div class="img">\r\n                        <img src="' +
((__t = ( require('./images/contact-icon1.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </div>\r\n                    <span>+86 13698668260 <br>+86 13698668260</span>\r\n                </dd>\r\n                <dd>\r\n                    <div class="img">\r\n                        <img src="' +
((__t = ( require('./images/contact-icon2.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </div>\r\n                    <span>info@bridge-md.com</span>\r\n                </dd>\r\n                <dd>\r\n                    <div class="img">\r\n                        <img src="' +
((__t = ( require('./images/contact-icon3.png') )) == null ? '' : __t) +
'" alt="">\r\n                    </div>\r\n                    <span>No.115,Zhujia Rd.Kunshan, Jiangsu, China.</span>\r\n                </dd>\r\n            </dl>\r\n        </div>\r\n        <div class="ewm">\r\n            <img src="' +
((__t = ( require('./images/footer-ewm.png') )) == null ? '' : __t) +
'" alt="">\r\n            <p>二维码</p>\r\n        </div>\r\n    </div>\r\n    <div class="copy">\r\n        <div class="left">\r\n            Copyright © 2024 BRIDGE  All Rights Reserved\r\n            <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备12002252号-4</a> \r\n        </div>\r\n        <div class="right">\r\n            Website construction | \r\n            <a href="http://dongbaqu.com/" target="_blank">dongbaqu</a>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}