import './index.scss'
$("header .nav li").hover(function(){
    $("header .nav li .navchild").stop().slideUp()
    $("header .nav li .navchild dl dt .xiala").hide()
    $(".header-bg").stop().slideDown()
    $(this).find(".navchild").stop().slideDown()
}
,function(){
    $(".header-bg").hide()
    $(this).find(".navchild").hide()
    $("header .nav li .navchild dl dt .xiala").hide()
})
$(".header-bg").mouseleave(function(){
    $(".header-bg").stop().slideUp()
    $("header .nav li .navchild").stop().slideUp()
})

$("header .nav li").eq(0).hover(function(){
    $(".header-bg").stop().slideUp()
})
$("header .nav li .navchild dl dt").hover(function(){
    $("header .nav li .navchild dl dt .xiala").hide()
    $(this).find(".xiala").show();
})

$(".hamburger").click(function(){
    $(this).toggleClass("active");
    $(".mob-nav").toggleClass("active");
})
$(".mob-nav .nLi").click(function() {
    $(this).find(".sub").slideToggle();
    $(this).toggleClass("active");
})


var prevScrollPos = 0;

$(window).scroll(function() {
  var currentScrollPos = $(window).scrollTop();
  var scrollDirection = currentScrollPos - prevScrollPos;
  if (scrollDirection > 0) {
    $("header.on").addClass("active");
    $(".subnav").addClass("active");
    $(".header-bg").hide();
    $("header .nav li .navchild").hide()
  } else {
    $("header.on").removeClass("active");
    $(".subnav").removeClass("active");
  } 
  prevScrollPos = currentScrollPos;
});
//滚动定位
$(document).ready(function() {
    if (location.hash) {
        setTimeout(function() {
            var targetOffset = $(location.hash).offset().top;
        console.log(targetOffset)
            $('html, body').animate({
                scrollTop: targetOffset - 98
            }, 500);
        }, 10);
    }
});
  